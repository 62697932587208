@import "~@verified/veform/themes/default/style.scss";

.main {
	max-width: 750px !important;
}

.btn-plus {
	background: #218BCB;
	display: inline-block;
	border-radius: 50%;
	color: #fff;
	padding: 6px;
	font-size: 26px;
	line-height: 15px;
	&:hover {
		cursor: pointer;
		background: #1c74aa;
	}
	margin: -5px 5px;
}

.btn-minus {
	background: #218BCB;
	display: inline-block;
	border-radius: 50%;
	color: #fff;
	padding: 6px 9px;
	font-size: 26px;
	line-height: 15px;
	&:hover {
		cursor: pointer;
		background: #1c74aa;
	}
	margin: -5px 5px;
}

.btn-minus-gray {
	background: #c1c1c1 !important;
	&:hover {
		cursor: not-allowed !important;
	}
}

.loader {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	pointer-events: none;
	z-index: 1;
	padding: 10px;
	background: white;
	border-radius: 42px;	
  }
  .smooth,
  .smooth * {
	transition: all 0.5s ease;
  
	&.delay,
	& .delay {
	  transition-delay: 0.5s;
	}
  }